.container {
    margin: auto;
    width: var(--container-width);
}

header {
    border-bottom: 1px solid #d9d9d9;
}

header nav {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}