:root {
    /* Theme colours */
    --joy-palette-main-100: #D6CFCD;
    --joy-palette-main-100: #BAB2AB;
    --joy-palette-main-100: #9F978A;
    --joy-palette-main-100: #837E69;
    --joy-palette-main-100: #666748;
    --joy-palette-main-100: #444A28;
    --joy-palette-main-100: #1F2E07;
    --joy-palette-main-100: #102705;
    --joy-palette-main-100: #052004;
}

@media(max-width: 500px) {
    :root {
        --container-width: 90%;
    }
}


@media(min-width: 500px) {
    :root {
        --container-width: 85%;
    }
}


@media(max-width: 768px) {
    :root {
        /* Typography */
        --h1: 38px;
        --h2: 30px;
        --h3: 24px;
        --h4: 20px;
        --body-large: 20px;
        --body-medium: 16px;
        --body-regular: 14px;
    }
}

@media(min-width: 768px) {
    :root {
        /* Typography */
        --h1: 56px;
        --h2: 38px;
        --h3: 32px;
        --h4: 24px;
        --body-large: 22px;
        --body-medium: 18px;
        --body-regular: 15px;

        /* Container width */
        --container-width: 700px
    }
}

@media(min-width: 1024px) {
    :root {
        /* Typography */
        --h1: 64px;
        --h2: 42px;
        --h3: 35px;
        --h4: 25px;
        --body-large: 24px;
        --body-medium: 20px;
        --body-regular: 16px;

        /* Container width */
        --container-width: 1000px
    }
}

@media(min-width: 1400px) {
    :root {
        --container-width: 1200px;
    }
}