@font-face {
    font-family: "Instrument Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 300 800;
    src: url('../theme/Instrument\ Sans.ttf');
}

.secondary-text {
    color: var(--secondary-text);
}